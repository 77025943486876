<script lang="ts" setup>
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()

const handlePlay = () => {
  playerStore.play()
}

const handlePause = () => {
  playerStore.pause()
}
</script>

<template>
  <div v-if="playerStore.media" class="relative flex items-center justify-between gap-4 bg-white p-4">
    <div class="flex items-center gap-3 overflow-x-hidden">
      <div v-if="playerStore.media.logo" class="size-12 rounded-lg">
        <NuxtLink :to="playerStore.media.route">
          <PlayerLogo />
        </NuxtLink>
      </div>
      <div class="flex-1 shrink-0 flex flex-col gap-1 justify-center overflow-hidden">
        <PlayerTitle />
        <NuxtLink :to="playerStore.media.route" class="text-sm font-medium truncate hyphens-auto">
          {{ playerStore.media.subTitle }}
        </NuxtLink>
      </div>
    </div>
    <div class="flex-none flex items-center">
      <button v-if="!playerStore.preRoll && !playerStore.isPlaying" aria-label="Play" @click="handlePlay">
        <Icon name="solar:play-circle-bold" size="58" />
      </button>

      <button v-else-if="playerStore.isLoading" aria-label="Loading">
        <Icon class="animate-spin" name="mingcute:loading-3-fill" size="58" />
      </button>

      <button v-else-if="!playerStore.preRoll && playerStore.isPlaying" aria-label="Pause" @click="handlePause">
        <Icon name="solar:pause-circle-bold" size="58" />
      </button>
    </div>
  </div>
</template>

<style scoped></style>
